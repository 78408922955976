import { Asset } from 'contentful';
import get from 'lodash/get';

export const getImageUrlFromAsset = (image: Asset): string => {
    const url = get(image, 'fields.file.url', '');

    return `https:${url}`;
};

export const getAssetsUrls = (assets: Asset[]) => {
    return assets.map((asset) => getImageUrlFromAsset(asset));
};
