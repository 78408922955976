import { NAME as ACCOUNT_NAME } from '@app/account/constants';
import { NAME as MARKETING_NAME } from '@app/marketing/constants';

// Hooks
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

// Utils
import { fetchMarketingData } from '@core/contentful/fetchMarketingData';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// UI
import LoginForm from '@app/account/components/LoginForm';
import DefaultLayout from '@ui/layouts/Default.layout';
import LoginBanner from '@app/marketing/components/LoginBanner';
import MetaTags from '@ui/components/MetaTags';

// Types
import type { GetStaticProps, NextPage } from 'next';
import type { LoginBannerData } from '@app/marketing/types';
import type { Language } from '@ui/components/LanguageSwitch';

interface Props {
    loginBanner?: LoginBannerData;
}

export const LoginPage: NextPage<Props> = ({ loginBanner }) => {
    const { t } = useTranslation(ACCOUNT_NAME);
    const { locale, pathname, query } = useRouter();

    return (
        <>
            <MetaTags
                title={t('page-title-login')}
                description={t('page-description-login')}
                locale={locale}
                pathname={pathname}
            />

            <DefaultLayout>
                <div className="mx-auto flex w-full max-w-md flex-col space-y-24 lg:max-w-full lg:flex-row lg:items-start lg:space-x-24 lg:space-y-0">
                    <div className="flex-1">
                        <LoginForm
                            welcomeUrl={
                                Array.isArray(query.welcomeUrl)
                                    ? query.welcomeUrl[0]
                                    : query.welcomeUrl
                            }
                        />
                    </div>

                    {loginBanner && <LoginBanner data={loginBanner} />}
                </div>
            </DefaultLayout>
        </>
    );
};

export default LoginPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const response = await fetchMarketingData(locale as Language);

    return {
        props: {
            ...(await serverSideTranslations(locale, [MARKETING_NAME, ACCOUNT_NAME, 'common'])),
            // Will be passed to the page component as props
            loginBanner: response.fields.loginBanner,
        },
        revalidate: 60,
    };
};
