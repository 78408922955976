import { getImageUrlFromAsset } from '@core/contentful/helper';

// UI
import { ChevronRightIcon } from '@heroicons/react/24/solid';

// Types
import { LoginBannerData } from '@app/marketing/types';

export interface Props {
    data: LoginBannerData;
}

const LoginBanner = ({ data }: Props) => {
    const { title, cta, backgroundColor, image, link } = data.fields;

    return (
        <div
            data-cy="login-banner"
            className="relative flex-1 flex-col overflow-hidden rounded-3xl bg-gray-100 text-center text-gray-700"
            style={{ backgroundColor }}
        >
            <div className="px-16 pb-4 pt-16">
                <h3 className="text-3xl font-bold">{title}</h3>
                <a
                    href={link}
                    className="flex items-center justify-center pt-4 hover:text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                >
                    {cta}
                    <ChevronRightIcon className="h-4 w-4" />
                </a>
            </div>
            <div
                className="relative h-[280px] w-full bg-gray-100 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${getImageUrlFromAsset(image)})` }}
            />
        </div>
    );
};

export default LoginBanner;
