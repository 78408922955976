import React from 'react';

// UI
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

// Types
export interface Props {
    togglePassword: () => void;
    revealPassword: boolean;
}

const revealPassIconClassName =
    'h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500 transition-colors duration-150';

const RevealPasswordEye: React.FC<Props> = ({ togglePassword, revealPassword }) =>
    revealPassword ? (
        <EyeSlashIcon className={revealPassIconClassName} onClick={togglePassword} />
    ) : (
        <EyeIcon className={revealPassIconClassName} onClick={togglePassword} />
    );

export default RevealPasswordEye;
