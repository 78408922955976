import type { FC } from 'react';

interface Props {
    className?: string;
    label?: string;
    symbol: string;
}

const Emoji: FC<Props> = ({ className, label, symbol }) => (
    <span
        className={`emoji ${className || ''}`}
        role="img"
        aria-label={label ? label : ''}
        aria-hidden={label ? 'false' : 'true'}
    >
        {symbol}
    </span>
);

export default Emoji;
