import React from 'react';
import Head from 'next/head';

// Types
export interface Props {
    title: string;
    description: string;
    locale: string;
    pathname: string;
    image?: string;
}

const MetaTags: React.FC<Props> = ({ title, description, image, locale, pathname }) => {
    const sharingImage = image ? image : `og-meta-default-${locale}.jpg`;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />

            {/*Open Graph*/}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`/${sharingImage}`} />
            <meta
                property="og:url"
                content={`https://start.perspective.co${locale === 'en' ? '/en' : ''}${pathname}`}
            />
            <meta property="og:site_name" content="perspective.co" />

            {/*Twitter*/}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={sharingImage} />
            <meta name="twitter:site" content="@perspective__co" />
            <meta name="twitter:creator" content="@perspective__co" />

            {/*Robots*/}
            <meta name="robots" content="noindex, nofollow" />
            <meta name="googlebot" content="noindex, nofollow" />
        </Head>
    );
};

export default MetaTags;
